import { useEffect, useState } from "react"

import style from "./index.module.css"
import { axios } from "../../../axios"
import { genderStr, getSexRole } from "../profile/dict"
import { useNavigate } from "react-router-dom"
import { Dislike, Like } from "./dislike"


export default function FindYou(){

    const navi = useNavigate()

    const [profile,setProfile] = useState({})

    const [stopMsg,setStopMsg] = useState({
        error:false,
        msg:""
    }) 

    const [showWarning,setShowWarning] = useState(true)

    useEffect(()=>{
        //获取一个推介资料
        getRecommend()
        document.body.style.overflow = "hidden"
        return ()=>{
            document.body.style.overflow = "auto"
        }
    },[])

    const [showDislike,setShowDislike] = useState(false)
    const [showLike,setShowLike] = useState(false)

    return <div className={style.container}>
        {showWarning&& <div className={style.warning}>
            <div>
                <p>
                    <span className={style.btn} onClick={()=>{
                        document.body.style.overflow='auto'
                        if(stopMsg.error){
                            navi("/square/paired")
                        }else{
                            setShowWarning(false);
                        }
                    }}>x</span>
                </p>
                请认真对待每一次缘分。
                <p>未匹配前将互相看不到对方联系方式，需要匹配后再查看对方资料进行下一步。</p>

                <div>
                    {stopMsg.error && <h4>{stopMsg.msg}</h4>}
                </div>
            </div>
        </div>}


        {!showWarning&&<div>
            <div className={style.profile}>
                <h3><span className={style.gray}>灰色</span>条目匹配后可见</h3>
                
                <div className={style.item}>
                    <span>昵称</span>
                    <div>{profile.name}</div>
                </div>

                <div className={style.item} >
                    <span>性别</span>
                    <div>{genderStr(profile.gender)}</div>
                </div>

                <div className={style.item}>
                    <span>年龄</span>
                    <div>{profile.age}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<17))!==0?style.hide:""].join(" ")}>
                    <span>生日</span>
                    <div>{profile.birthday}</div>
                </div>

                <div className={style.signature}>
                    <span>个性签名</span>
                    <div>{profile.signature}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<14))!==0?style.hide:""].join(" ")}>
                    <span>性偏向</span>
                    <div>{(profile.hideCode&(1<<14))===0?getSexRole(profile.sexRole):""}</div>
                </div>

                <div className={style.item}>
                    <span>身高</span>
                    <div>{profile.height}</div>
                </div>
                <div className={style.item}>
                    <span>体重</span>
                    <div>{profile.weight}</div>
                </div>
                <div className={style.item}>
                    <span>所在城市</span>
                    <div>
                        {profile.province?profile.province:''} {profile.city?profile.city:''}
                    </div>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<10))!==0?style.hide:""].join(" ")}>
                    <span>家乡</span>
                    <span>{profile.hometown}</span>
                </div>

                <div className={style.item}>
                    <span>职业</span>
                    <span>{profile.job}</span>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<8))!==0?style.hide:""].join(" ")}>
                    <span>年薪</span>
                    <span>{profile.salary}</span>
                </div>
                <div className={style.item}>
                    <span>学历</span>
                    <span>{profile.education}</span>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<6))!==0?style.hide:""].join(" ")}>
                    <span>学校</span>
                    <span>{profile.school}</span>
                </div>

                <div className={style.item}>
                    <span>专业</span>
                    <span>{profile.major}</span>
                </div>
                <div className={[style.detail,(profile.hideCode&(1<<4))!==0?style.hide:""].join(" ")}>
                    <span>详细描述</span>
                    <div>
                        {profile.detail}
                    </div>
                </div>
                <div className={[style.hide]}>
                    <span>联系方式</span>
                    <span>{profile.contact}</span>
                </div>
            </div>

        </div>}


        <div className={style.confirmBtns}>
            <div>
                <span onClick={()=>{
                    document.body.style.overflow='hidden'
                    setShowDislike(true)
                }}>不合适</span>
                <span onClick={()=>{
                    document.body.style.overflow='hidden'
                    setShowLike(true)
                }}>继续沟通</span>
            </div>
        </div>

        {showDislike&&<Dislike sessionId={profile.sessionId} close={(v)=>{
            setShowDislike(false)
            //执行了不喜欢操作成功
            if(v===true){
                //倒计时3秒 开始刷新获取下一个
            }else{
                //取消
            }
        }} />}
        {showLike&&<Like sessionId={profile.sessionId} close={(v)=>{
            setShowLike(false)
            if(v===true){
                //倒计时3秒 开始刷新获取下一个
            }else{
                //取消
            }
        }} />}
    </div>

    //获取推介
    function getRecommend(){
        axios.get("/api/square/flow/recommend").then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
            }else{
                setStopMsg({msg:data.msg,error:true})
            }
        }).catch(err=>{
            console.log(err)
        })
    }



}


