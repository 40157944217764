import { useParams, useSearchParams } from "react-router-dom"
import { axios } from "../../../axios"
import { useEffect, useState } from "react"
import { Button, Input } from "antd"

import style from "../css/node.detail.module.css"
import Alert from "../../my-com/alert"
import { NewList } from "./new"
import { SelectedList } from "./selected"

export default function NodeDetail(){
    const id = useParams().id
    const [searchParams,setSearchParams] = useSearchParams()
    const page = searchParams.get("page")?parseInt(searchParams.get("page")):1
    const tab = searchParams.get("tab")?searchParams.get("tab"):"new"
    const order = searchParams.get("order")?searchParams.get("order"):"desc"
    useEffect(()=>{
        getNodeDetail(id)
        // eslint-disable-next-line
    },[])

    const [node,setNode] = useState({})

    //最新列表 
    const [posts,setPosts] = useState({
        list:[],
        total:0,
        page
    })
    function getPosts(tmpPage){
        axios.get("/api/topic/post/posts-of-node",{
            params:{
                nodeId:id,
                page:tmpPage?tmpPage-1:posts.page-1
            }
        }).then(({data})=>{
            setPosts({...posts,
                list:data.data.list,
                total:data.data.total,
                page:tmpPage?tmpPage:posts.page})
            setSearchParams(pre=>{
                let params = new URLSearchParams(pre)
                params.set("page",tmpPage?tmpPage:posts.page)
                return params
            })
        }).catch(err=>{})
    }

    //精选列表
    const [selected,setSelected] = useState({
        list:[],
        total:0,
        page:page,
        order:order
    })
    function getSelected(page,order){
        axios.get("/api/topic/post/selected-of-node",{
            params:{
                page:page-1,
                order:order?order:selected.order,
                nodeId:id
            }
        }).then(({data})=>{
            if(data.code===200){
                setSelected({
                    list:data.data.list,
                    total:data.data.total,
                    page:page,
                    order:order?order:selected.order
                })
                setSearchParams(pre=>{
                    let params = new URLSearchParams(pre)
                    params.set("page",page)
                    if(order){
                        params.set("order",order)
                    }
                    return params
                })
            }
        }).catch(err=>{})   
    }

    //当前tab
    const [currentTab,setCurrentTab] = useState(tab)

    //控制提交按钮
    const [submitBtnDisable,setSubmitBtnDisable] = useState(false)

    const [postToAdd,setPostToAdd] = useState({ title:"",description:"" })
    const [showAdd,setShowAdd] = useState(false)
    const [showDescription,setShowDescription] = useState(false)

    return <div className={style.container}>
        <div>
            <h3 className={style.title}>
                # {node.node}
                <span onClick={()=>displyDescription(true)}>?</span>
            </h3>
            <div className={style.btns}>
                <div className={style.addBtn}>
                    <span onClick={()=>setShowAdd(true)}>新话题</span>
                </div>
                <div className={style.archiveBtn}>
                    {node.archived?<span onClick={unarchiveTopic}>取消收藏</span>:
                    <span onClick={archiveTopic}>收藏节点</span>}
                </div>
            </div>
        </div>


        <div className={style.posts}>
            <p className={style.btns}>
                <span className={currentTab==='new'?style.active:''}
                    onClick={()=>{
                        setCurrentTab("new")
                        setSearchParams(pre=>{
                            let params = new URLSearchParams(pre)
                            params.set("tab","new")
                            params.set("page","1")
                            return params
                        })
                    }}>
                    最新
                </span>
                <span className={currentTab==='selected'?style.active:''}
                    onClick={()=>{
                        setCurrentTab("selected")
                        setSearchParams(pre=>{
                            let params = new URLSearchParams(pre)
                            params.set("tab","selected")
                            params.set("page","1")
                            return params
                        })
                    }}>
                    精选
                </span>
                {currentTab==='selected'&&selected.order==='asc'&&<span>
                    <Button type="dashed" size="small" style={{fontSize:'0.7em'}}
                        onClick={()=>{
                            getSelected(1,'desc')
                            setSearchParams((pre)=>{
                                let newParams = new URLSearchParams(pre)
                                newParams.set("order","desc")
                                return newParams
                            })
                        }}>从低到高</Button></span>}
                {currentTab==='selected'&&selected.order==='desc'&&<span>
                    <Button type="dashed" size="small" style={{fontSize:'0.7em'}}
                        onClick={()=>{
                            getSelected(1,'asc')
                            setSearchParams((pre)=>{
                                let newParams = new URLSearchParams(pre)
                                newParams.set("order","asc")
                                return newParams
                            })
                        }}
                    >从高到低</Button></span>}
            </p>


            {currentTab==='new'&&<NewList id={id} 
                posts={posts}
                getPosts={getPosts} />}
            {currentTab==='selected'&&<SelectedList page={page}
                order={order}
                list={selected}
                getSelected={()=>getSelected(page,order)} />}
        </div>

        {showAdd&&<div className={style.addPage}>
            <div className={style.addContainer}>
                <div className={style.addTitleInput}>
                    <Input placeholder="标题" 
                        maxLength={150}
                    onChange={e=>setPostToAdd({...postToAdd,title:e.target.value}) } />
                </div>

                <div className={style.addContentInput}>
                    <Input.TextArea placeholder="内容"  
                        maxLength={2000}
                        autoSize={{minRows:6}}
                        onChange={e=>setPostToAdd({...postToAdd,description:e.target.value})}/>
                </div>
  
                <div>
                    <Button disabled={submitBtnDisable?true:false} onClick={()=>{
                        addPost()
                    }}>提交</Button>
                    <Button onClick={()=>setShowAdd(false)}>取消</Button>
                </div>
            </div>
        </div>}

        {showDescription&&<div className={style.description}>
            <div>
                <div className={style.content}>
                    <div>
                        <span onClick={()=>displyDescription(false)} className={style.closeBtn}>关闭</span>
                    </div>
                    <div>
                        {node.description}
                    </div>
                </div>
            </div>
        </div>}
    </div>

    function displyDescription(v) {
        setShowDescription(v)
        if(v){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    }


    function getNodeDetail(id) {
        axios.get("/api/topic/node",{
            params:{
                id
            }
        }).then(({data})=>{
            if(data.code===200){
                setNode(data.data)
            }
        }).catch(err=>{})
    }

    function addPost(){
        if(!postToAdd.title){
            alert("标题不能为空")
            return
        }
        if(postToAdd.title.length>150){
            Alert.alert("标题不能超过150个字符")
            return
        }
        if(!postToAdd.description){
            alert("话题描述不能为空")
            return
        }
        if(postToAdd.description.length>2000){
            Alert.alert("话题描述不能超过2000个字符")
            return
        }
        setSubmitBtnDisable(true)
        axios.post("/api/topic/post",{
            ...postToAdd,
            nodeId:id
        }).then(({data})=>{
            if(data.code===200){
                setShowAdd(false)
                setSubmitBtnDisable(false)
                getPosts(1)
            }else{
                setSubmitBtnDisable(false)
                Alert.alert(data.msg)
            }
        }).catch(err=>{
            setSubmitBtnDisable(false)
        })
    }

    function archiveTopic(){
        axios.post("/api/topic/node/archive",{
            nodeId:id
        }).then(({data})=>{
            if(data.code===200){
                getNodeDetail(id)
            }
        }).catch(err=>{})
    }
    function unarchiveTopic(){
        axios.delete("/api/topic/node/archive",{
            params:{
                nodeId:id
            }
        }).then(({data})=>{
            if(data.code===200){
                getNodeDetail(id)
            }
        }).catch(err=>{})
    }


}



