import { Link } from "react-router-dom"

import style from "./new.module.css"
import { useEffect, useState } from "react"
import { Pagination } from "antd"
import { axios } from "../../../axios"
// 话题详情页 最新的话题
export function NewList({posts,getPosts,id}){

    useEffect(()=>{
        getPosts(posts.page)
        // eslint-disable-next-line
    },[])
    useEffect(()=>{
        if(posts.page === 1){
            getStickedOfNode()
        }
        // eslint-disable-next-line
    },[posts.page])

    const [sticked,setSticked] = useState({
        list:[]
    })


    return <div className={style.newlist}>
        {posts.page === 1 && sticked.list.map(e=><div key={e.id} className={style.stickedPost}>
            <div>
                <sup style={{fontSize:'0.5em',color:"goldenrod"}}>置顶</sup><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
            </div>
        </div>)}

        {posts.list.map(e=><div key={e.id} className={style.post}>
                <div>
                    <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                </div>
        </div>)}

        <Pagination
            current={posts.page}
            total={posts.total}
            pageSize={18}
            onChange={page=>{
                getPosts(page)
            }}
        />
    </div>

    function getStickedOfNode() {
        axios.get("/api/topic/post/stickies-all-and-node", {
            params: {
                page: posts.page-1,
                nodeId:id
            }
        }).then(({ data }) => {
            if (data.code === 200) {
                setSticked({
                    list: data.data
                })
            }
        })
    }

}