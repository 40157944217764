import { useNavigate } from "react-router-dom";
import Btn from "../../my-com/btn";



import style from "./index.module.css"

export default function About(){
    const navi = useNavigate()
    return <div className={style.container}>
        <p className={style.header}>
            <Btn text="返回" onClick={()=>navi("/user")} />
        </p>
        
        <div>
            <p>人生终归落于平淡，我不喜欢片刻欢愉后空虚袭来的感觉，我想要爱。</p>
        </div>
        <div>
            社区定位：致力于做一个纯净的以长久亲密关系为结果的找对象途径， 一个简单、纯粹的学习、成长社区。
        </div>
        <div>
            <p>如果你也厌倦了or不喜欢'约？'‘看看你？’，欢迎尝试下浮尘。浮尘于世，总要有个归宿。希望你能在
                这里找到你的归宿。love is everything~
            </p>
        </div>
    </div>
}