import { useEffect, useState } from "react"
import { axios } from "../../../../axios"


import style from "./index.module.css"
import { useNavigate, useParams } from "react-router-dom"
import { DB} from "../../../../tools"

export default function UserProfile(){

    const [profile,setProfile] = useState({})
    const navi = useNavigate()
    const userId = parseInt(useParams().userId)

    useEffect(()=>{
        getUserProfile(userId)
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        <div className={style.items}>
            <div className={style.item}>
                <span className={style.placeholder}>帐号</span>
                <span style={{color:"gray"}}>{profile.username}</span>
            </div>
            <div className={style.item}>
                <span className={style.placeholder}>昵称:</span>
                <span>{profile.nickname}</span>
            </div>
            <div className={style.itemNextLine}>
                <div className={style.placeholder}>个性签名:</div>
                <div className={style.content}>
                    <span className={style.text}>
                        {profile.signature}
                    </span>
                </div>
            </div>
            <div className={style.item}>
                <span className={style.placeholder}>性别</span>
                <span>{profile.gender===1?"男":"女"}</span>
            </div>
           
        </div>

    </div>

    function getUserProfile(userId){
        new DB().getByKey("profiles",userId)
        .then(data=>{
            setProfile(data)
        }).catch(err=>{
            axios.get('/api/user/profile',{
                params:{
                    userId
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    setProfile(data.data)
                    new DB().add("profiles",userId,data.data)
                }else{
                    navi("/flash")
                }
            })
        })
    }

}









