import { Route, Routes } from "react-router-dom";
import TopicHome from "./home";
import TopicPostDetail from "./post.detail";
import TopicNodes from "./node.all";
import Replys from "./replys";
import NodeAddTopic from "./node.addpost";
import NodeDetail from "./node-detail";




export default function Topic(){

    return <div>
        <Routes>
            <Route path="" element={<TopicHome />} />
            <Route path="node/all" element={<TopicNodes />}/>
            <Route path="node/detail/:id" element={<NodeDetail />} />
            <Route path="node/detail/:id/add" element={<NodeAddTopic />} />
            
            <Route path="post/detail/:id" element={<TopicPostDetail />} />
            <Route path="post/reply/:id" element={<Replys />} />
        </Routes>
    </div>
}

