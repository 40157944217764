import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { axios } from "../../axios";

import style from "./index.module.css"
import { DB } from "../../tools";
export default function User(){

    const navigate = useNavigate();
    const navi = useNavigate()
    const [profile,setProfile] = useState({
        vipInfo:{
            vip:false
        }
    })
    const [level,setLevel] = useState(0)
    const [checkInStatus,setCheckInStatus] = useState(true)
    const [banStatus,setBanStatus] = useState({
        banedStatus:0,
        banedToDatetime:"",
        banedReason:""
    })

    useEffect(()=>{
        const isLogin = localStorage.getItem("info")!==null
        if(!isLogin){
            navigate("/login")
        }else{
            getUserProfile()
            getUserPoints()
            getCheckInStatus()
        }
        // eslint-disable-next-line
    },[])


    return<div className={style.container}>
        <div className={style.top}>
            <div className={style.username} onClick={()=>navigate("/user/profile/"+profile.userId)}>
                <div>
                    <span className={style.placeholder}>昵称：</span>
                    {profile.nickname}
                </div>
                <div className={style.second}>
                    <span className={style.name}>
                        <span className={style.placeholder}>用户名：</span>
                        {profile.username}
                    </span>
                    <span className={style.placeholder}>&gt;</span>
                </div>
            </div>
            <div className={style.userNum}>
                <span className={style.pointsLevel} onClick={()=>navi("/user/points/"+profile.userId)}>等级:{level}</span>
                {checkInStatus?<span>已签到</span>:<span onClick={checkIn}>签到</span>}
            </div>

            <div className={style.userNum}>
                {profile.vipInfo.vip&&<>
                    <span onClick={()=>navi("/user/vip")}>正式成员</span>
                    <span onClick={()=>navi("/user/vip")}>有效期:{profile.vipInfo.endDate}</span>
                </>}

                {!profile.vipInfo.vip&&<>
                    <span onClick={()=>navi("/user/vip")}>有限使用</span>
                    <span onClick={()=>navi("/user/vip")}>加入大家</span>
                </>}
                
            </div>

            {banStatus.banedStatus===1&&<div className={style.ban}>
                <span>禁言中</span>
                <span>解除时间:{banStatus.banedToDatetime}(UTC+0)</span>
                <span>原因:{banStatus.banedReason}</span>
            </div>}
        </div>

        <div className={style.more}>
            <div>
                <span>公告</span>
            </div>
            <div>
                <Link to={"/setting"} className={style.settingBtn}>设置</Link>
            </div>
            <div onClick={()=>navi("/setting/about")}>
                <span>关于</span>
            </div>
            <div onClick={()=>navi("/setting/help")}>
                <span>帮助(必读)</span>
            </div>
            <div>
                <span>反馈</span>
            </div>
            <div onClick={()=>navi("/setting/rules")}>
                <span>社区规则</span>
            </div>
        </div>

    </div>


    function getUserProfile(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            new DB().getByKey("profiles",userId)
            .then(data=>{
                setProfile(data)
            }).catch(err=>{
                axios.get('/api/user/profile')
                .then(({data})=>{
                    if(data.code===200){
                        let profile = data.data
                        setProfile(profile)
                        new DB().add("profiles",userId,profile)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            })
        }
    }


    function getUserPoints(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            axios.get('/api/user/points',{
                params:{
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    let points = data.data
                    setLevel(points.level)
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }


    //获取签到状态
    function getCheckInStatus(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            axios.get('/api/user/check-in-state',{
                params:{
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    data = data.data
                    setCheckInStatus(data.checkIn)
                    setBanStatus({
                        banedStatus:data.banedStatus,
                        banedToDatetime:data.banedToDatetime,
                        banedReason:data.banedReason
                    })
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }

    //签到
    function checkIn(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            axios.get('/api/user/check-in',{
                params:{
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    setCheckInStatus(true)
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }
}
