import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import { Link, useSearchParams } from "react-router-dom"

import {Button, Pagination} from "antd"
import style from "../css/home.module.css"



function activeIndexToTab(index){
    if(!index){
        return null
    }
    if(parseInt(index)===0){
        return 'new'
    }
    if(parseInt(index)===1){
        return 'selected'
    }
    return null
}
export default function TopicHome(){


    const [archivedTopic,setArchivedTopic] = useState([])
    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })

    const [sticked,setSticked] = useState({
        list:[]
    })
    useEffect(()=>{
        if(postList.page === 1){
            getStickedOfAll()
        }
        // eslint-disable-next-line
    },[postList.page])

    const [selectedList,setSelectedList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18,
        order:"desc"
    })

    const [showTab,setShowTab] = useState('new')
    const [searchParams,setSearchParams]=useSearchParams()

    //设置url参数，active默认0，最新。1 是精选
    //page 对应的页数
    //order 排序方式
    useEffect(()=>{
        const page = searchParams.get("page")
        const activeTabIndex = searchParams.get("active")
        const selectedOrder = searchParams.get("order")
        const ifSet={
            active:activeIndexToTab(activeTabIndex)===null,
            page:page===null,
            order:selectedOrder===null||(selectedOrder!=='asc'&&selectedOrder!=='desc'),
            set:()=>{
                return ifSet.active||ifSet.page||ifSet.order
            }
        }
        let showTabTmp = activeIndexToTab(activeTabIndex)?activeIndexToTab(activeTabIndex):'new'
        if(!ifSet.active){
            setShowTab(showTabTmp)
        }
        let pageTmp=ifSet.page?1:parseInt(page)
        if(showTabTmp==='new'){
            getPosts(pageTmp)
        }else{
            if(ifSet.order){
                getSelectedPost(selectedList.page,'desc')
            }else{
                getSelectedPost(selectedList.page,selectedOrder)
            }
        }
        getUserArchivedNodes()
        // eslint-disable-next-line
    },[])

    
    return <div className={style.container}>
        <div>
            <h3>我的节点</h3>
            <div className={style.allNodes}>
                {archivedTopic.map(e=><div key={e.id} className={style.archivedTopic}>
                    <Link to={'/topic/node/detail/'+e.id}>{e.node}</Link>
                </div>)}
            </div>
            <div className={style.toAllTopicBtn}>
                <Link to={'/topic/node/all'}>所有节点</Link>
            </div>
        </div>


        <div className={style.recommend}>
            <div className={style.activeTabs}>
                <span className={`${showTab === 'new' ? style.activeTab : ''}`} 
                    onClick={()=>{
                        setShowTab('new')
                        setSearchParams((pre)=>{
                            let newParams = new URLSearchParams(pre)
                            newParams.set("active",0)
                            newParams.set("page",1)
                            return newParams
                        })
                        getPosts(1)
                        }}>最新</span>
                <span className={`${showTab === 'selected' ? style.activeTab : ''}`} 
                    onClick={()=>{
                        setShowTab('selected')
                        setSearchParams((pre)=>{
                            let newParams = new URLSearchParams(pre)
                            newParams.set("active",1)
                            newParams.set("page",1)
                            return newParams
                        })
                        getSelectedPost(1)
                    }}>精选</span>
                
                {showTab==='selected'&&selectedList.order==='asc'&&<span>
                    <Button type="dashed" size="small" style={{fontSize:'0.7em'}}
                        onClick={()=>{
                            getSelectedPost(1,'desc')
                            setSearchParams((pre)=>{
                                let newParams = new URLSearchParams(pre)
                                newParams.set("order","desc")
                                return newParams
                            })
                        }}>从低到高</Button></span>}
                {showTab==='selected'&&selectedList.order==='desc'&&<span>
                    <Button type="dashed" size="small" style={{fontSize:'0.7em'}}
                        onClick={()=>{
                            getSelectedPost(1,'asc')
                            setSearchParams((pre)=>{
                                let newParams = new URLSearchParams(pre)
                                newParams.set("order","asc")
                                return newParams
                            })
                        }}
                    >从高到低</Button></span>}
            </div>

            {showTab==='new'&&<div>
                {postList.page === 1 && sticked.list.map(e=><div key={e.id} className={style.stickedPost}>
                    <div>
                        <sup style={{fontSize:'0.5em',color:"goldenrod"}}>置顶</sup>
                        <span style={{fontSize:'0.8em'}}>{e.node}·&nbsp;&nbsp;</span>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                        
                    </div>
                </div>)}
                {postList.list.map(e=><div key={e.id}>
                        <span style={{fontSize:'0.8em'}}>{e.node}·&nbsp;&nbsp;</span>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                    </div>)}
                <Pagination 
                    total={postList.total}
                    current={postList.page}
                    pageSize={18}
                    showSizeChanger={false} 
                    onChange={p=>getPosts(p)}
                />
            </div>}

            {/* 精选 */}
            {showTab==='selected'&&<div>
                {selectedList.list.map(e=><div key={e.id}>
                        <span style={{fontSize:'0.8em'}}>{e.node}·&nbsp;&nbsp;</span>
                        <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                    </div>)}
                <Pagination 
                    total={selectedList.total}
                    current={selectedList.page}
                    pageSize={18}
                    showSizeChanger={false} 
                    onChange={p=>getSelectedPost(p)}
                />
            </div>}
        </div>
    </div>



    
    function getPosts(page){
        axios.get("/api/topic/post/list",{
            params:{
                page:page - 1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total>54?54:data.data.total,
                    list:data.data.list,
                    page:page
                })
                setSearchParams((pre)=>{
                    let newParams = new URLSearchParams(pre)
                    newParams.set("page",page)
                    newParams.set("active",0)
                    return newParams
                })
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    //获取收藏的话题
    function getSelectedPost(page,order){
        axios.get("/api/topic/post/selected",{
            params:{
                page:page-1,
                order:order?order:selectedList.order
            }
        }).then(({data})=>{
            if(data.code===200){
                setSelectedList({
                    ...selectedList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page,
                    order:order?order:selectedList.order
                })
                setSearchParams((pre)=>{
                    let newParams = new URLSearchParams(pre)
                    newParams.set("page",page)
                    if(order){
                        newParams.set("order",order)
                    }
                    newParams.set("active",1)
                    return newParams
                })
            }
        }).catch(err=>{})
    }

    function getUserArchivedNodes(){
        axios.get("/api/topic/node/archived",{
            params:{
            }
        }).then(({data})=>{
            if(data.code===200){
                setArchivedTopic(data.data)
            }
        }).catch(err=>{})
    }

    //获取置顶的话题
    function getStickedOfAll() {
        axios.get("/api/topic/post/stickies-all", {
        }).then(({ data }) => {
            if (data.code === 200) {
                setSticked({
                    list: data.data
                })
            }
        })
    }

}