import { useEffect, useState } from "react"
import style from "./index.module.css"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { localDate, toFold } from "../../tools"
import { axios } from "../../axios"
import { Pagination } from "antd"
import { Pen } from "../../icons"

export default function Flash(){


    const [,setSearchParams]=useSearchParams()

    const location=useLocation()
    const page = new URLSearchParams(location.search).get("page")
    const navigate = useNavigate()
    const [flashs,setFlashs] = useState([])
    const [req,setReq] = useState({
        current:page==null?1:parseInt(page),//首页
        total:0
    })

    useEffect(()=>{
        getFlashs(req.current)
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        <div>
            <div className={style.content}>
                {flashs.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
                    onClick={()=>{
                    setTimeout(() => {
                        navigate("/flash/detail/"+e.id)
                    }, 200);}}>
                    <div className={style.itemHeader}>
                        <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </div>
                    <div>
                        <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                        {toFold(e.content)&&<p className={style.folder} style={{fontSize:"0.9em",color:"#ababab",padding:"0"}}>查看全部</p>}
                    </div>
                    <p className={style.footer}>
                        <span>回复{e.commentCount}</span>
                        <span>顶{e.up}</span>
                        <span>踩{e.down}</span>
                    </p>
                </div>)}
                <Pagination
                    total={req.total}
                    current={req.current}
                    pageSize={18}
                    onChange={p=>getFlashs(p)}
                />
            </div>
        </div>

        <span className={style.addIcon} onClick={()=>setTimeout(() => {
            navigate("/flash/add")
        }, 300)}>
            <Pen />
        </span>

    </div>

    function getFlashs(n){
        axios.get("/api/flash",{
            params:{
                page:n-1
            }
        }).then(({data})=>{
            if(data.code===200){
                setReq({...req,total:data.data.total,current:n})
                setFlashs(data.data.list)
                setSearchParams((pre)=>{
                    let newParams = new URLSearchParams(pre)
                    newParams.set("page",n)
                    return newParams
                })
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }


    
}