


// 复制url到剪切板

function share(uri){
    //从浏览器获取host
    let host = new URL(window.location.href)
    window.navigator.clipboard.writeText(host.origin+uri)
    .then(()=>{
        alert("链接已复制到剪切板")
    })
}



let checkLogin=()=>{
    let user = localStorage.getItem("info")
    if(!user){
        window.location.href='/#/login'
        return false
    }
    return true   
}

let localDate=(date)=>{
    if(!date){
        return "";
    }
    let tmp = new Date(date)
    if(!tmp){
        return "";
    }
    tmp = new Date(tmp.getTime()-tmp.getTimezoneOffset()*60000);
    let t2 = tmp.getFullYear()+"/"+(tmp.getMonth()+1)+"/"+tmp.getDate()+" "+tmp.getHours()+":"+tmp.getMinutes()
    return t2
}
let localDate2=(date)=>{
    if(!date){
        return "";
    }
    let tmp = new Date(date)
    if(!tmp){
        return "";
    }
    tmp = new Date(tmp.getTime()-tmp.getTimezoneOffset()*60000);
    let t2 = (tmp.getFullYear())+"/"+(tmp.getMonth()+1)+"/"+tmp.getDate()+" "+tmp.getHours()+":"+tmp.getMinutes()
    return t2
}

function isAdmin(){
    let info = localStorage.getItem("info")
    if(!info){
        return false
    }
    info = JSON.parse(info)
    let roles = info.roles
    return roles.includes('ADMIN')
}

// 判断是否折叠
function toFold(text){
    if(!text){
        return false
    }
    if(text.length>100){
        return true
    }
    //如果换行符超过3个 返回true
    let count = 0
    for(let i=0;i<text.length;i++){
        if(text[i]==='\n'){
            count++
        }
        if(count>3){
            return true
        }
    }
    return false
}

const DB_NAME="demo1"
class DB{
    constructor(){
        let storeName = "profiles";
        const request = indexedDB.open(DB_NAME, 1);
        request.onupgradeneeded = function (event) {
            //获取数据库对象
            const db = event.target.result;
            if(!db.objectStoreNames.contains(storeName)){
                console.log("store不存在")
                db.createObjectStore(storeName)
            }
        }
    }

    add = (storeName,key,value)=>{
        const request = indexedDB.open(DB_NAME, 1);

        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                //获取数据库对象
                const db = event.target.result;
                //获取事务对象
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);

                //先查
                const getResult = store.get(key);
                getResult.onsuccess = (event)=>{
                    let data = event.target.result
                    if(data){
                        console.log("查到了,值是:",data)
                        resolve(data)
                    }else{
                        const addReq = store.add(value, key);
                        addReq.onsuccess = (e)=>{
                            resolve("插入成功")
                        }
                        addReq.onerror = (e)=>{
                            reject("插入失败")
                        }
                    }
                }
            }
        })
    }

    getByKey = (storeName,key)=>{
        const request = indexedDB.open(DB_NAME, 1);

        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                //获取数据库对象
                const db = event.target.result;
                //获取事务对象
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                //获取数据
                const result = store.get(key);
                result.onsuccess = function (event) {
                    let data = event.target.result
                    if(data)
                        resolve(data)
                    else
                        reject("no")
                }
                result.onerror = (e)=>{
                    reject("no")
                }
            }
            request.onerror = (e)=>{
                reject("no1")
            }
        })
        
    }

    delete = (storeName,key)=>{
        const request = indexedDB.open(DB_NAME, 1);
        //数据库连接成功
        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                //先查是否存在 再删除
                const result = store.get(key);
                result.onsuccess = function (event) {
                    let data = event.target.result
                    if(data){
                        store.delete(key).onsuccess=(e)=>{
                            resolve("删除成功")
                        };
                    }else{
                        resolve("key不存在 无需删除")
                    }
                }
            }
            request.onerror = (e)=>{
                reject(e)
            }
        })
        
    }

    //清除仓库所有
    clear = (storeName)=>{
        const request = indexedDB.open(DB_NAME, 1);
        //数据库连接成功
        return new Promise((resolve,reject)=>{
            request.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction([storeName], 'readwrite');
                //获取存储对象
                const store = transaction.objectStore(storeName);
                store.clear().onsuccess = function(event){
                    resolve("清除成功")
                }
            }
            request.onerror = (e)=>{
                reject(e)
            }
        })
    }
}


function getNicknameOrUsername(user){
    if(user.nickname){
        return user.nickname
    }
    if(user.username){
        return user.username
    }
    return ""
}

function checkDate(data){
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if(regex.test(data)===false){
        return false
    }
    let tmps =data.split("-")
    let tmpsYear = parseInt(tmps[0])
    let tmpsMonth = parseInt(tmps[1])
    let tmpsDay = parseInt(tmps[2])

    let tmp = new Date(data)
    let year = tmp.getFullYear()
    let month = tmp.getMonth()+1
    let day = tmp.getDate()

    if(year!==tmpsYear || month!==tmpsMonth || day!==tmpsDay){
        return false
    }
    return true
}


function getProvince(province){
    let data = {
    "北京市": [],
    "上海市": [],
    "重庆市":[],
    "天津市":[],
    "四川省": ["成都市", "自贡市", "攀枝花市", "泸州市", "德阳市", "绵阳市", "广元市", "遂宁市", "内江市", "乐山市", "南充市", "眉山市", "宜宾市", "广安市", "达州市", "雅安市", "巴中市", "资阳市", "阿坝藏族羌族自治州", "甘孜藏族自治州", "凉山彝族自治州"],
    "江苏省": ["南京市", "无锡市", "徐州市", "常州市", "苏州市", "南通市", "连云港市", "淮安市", "盐城市", "扬州市", "镇江市", "泰州市", "宿迁市"],
    "广东省": ["广州市", "深圳市", "珠海市", "汕头市", "佛山市", "韶关市", "江门市", "湛江市", "茂名市", "肇庆市", "惠州市", "梅州市", "汕尾市", "河源市", "阳江市", "清远市", "东莞市", "中山市", "潮州市", "揭阳市", "云浮市"],
    "浙江省": ["杭州市", "宁波市", "温州市", "嘉兴市", "湖州市", "绍兴市", "金华市", "衢州市", "舟山市", "台州市", "丽水市"],
    "山东省": ["济南市", "青岛市", "淄博市", "枣庄市", "东营市", "烟台市", "潍坊市", "济宁市", "泰安市", "威海市", "日照市", "临沂市", "德州市", "聊城市", "滨州市", "菏泽市"],
    "河南省": ["郑州市", "开封市", "洛阳市", "平顶山市", "安阳市", "鹤壁市", "新乡市", "焦作市", "濮阳市", "许昌市", "漯河市", "三门峡市", "南阳市", "商丘市", "信阳市", "周口市", "驻马店市"],
    "湖北省": ["武汉市", "黄石市", "十堰市", "宜昌市", "襄阳市", "鄂州市", "荆门市", "孝感市", "荆州市", "黄冈市", "咸宁市", "随州市", "恩施土家族苗族自治州", "仙桃市", "潜江市", "天门市", "神农架林区"],
    "湖南省": ["长沙市", "株洲市", "湘潭市", "衡阳市", "邵阳市", "岳阳市", "常德市", "张家界市", "益阳市", "郴州市", "永州市", "怀化市", "娄底市", "湘西土家族苗族自治州"],
    "安徽省": ["合肥市", "芜湖市", "蚌埠市", "淮南市", "马鞍山市", "淮北市", "铜陵市", "安庆市", "黄山市", "滁州市", "阜阳市", "宿州市", "六安市", "亳州市", "池州市", "宣城市"],
    "福建省": ["福州市", "厦门市", "莆田市", "三明市", "泉州市", "漳州市", "南平市", "龙岩市", "宁德市"],
    "江西省": ["南昌市", "景德镇市", "萍乡市", "九江市", "新余市", "鹰潭市", "赣州市", "吉安市", "宜春市", "抚州市", "上饶市"],
    "辽宁省": ["沈阳市", "大连市", "鞍山市", "抚顺市", "本溪市", "丹东市", "锦州市", "营口市", "阜新市", "辽阳市", "盘锦市", "铁岭市", "朝阳市", "葫芦岛市"],
    "黑龙江省": ["哈尔滨市", "齐齐哈尔市", "鸡西市", "鹤岗市", "双鸭山市", "大庆市", "伊春市", "佳木斯市", "七台河市", "牡丹江市", "黑河市", "绥化市", "大兴安岭地区"],
    "吉林省": ["长春市", "吉林市", "四平市", "辽源市", "通化市", "白山市", "松原市", "白城市", "延边朝鲜族自治州"],
    "河北省": ["石家庄市", "唐山市", "秦皇岛市", "邯郸市", "邢台市", "保定市", "张家口市", "承德市", "沧州市", "廊坊市", "衡水市"],
    "陕西省": ["西安市", "铜川市", "宝鸡市", "咸阳市", "渭南市", "延安市", "汉中市", "榆林市", "安康市", "商洛市"],
    "海南省": ["海口市", "三亚市", "三沙市", "儋州市"],
    "山西省": ["太原市", "大同市", "阳泉市", "长治市", "晋城市", "朔州市", "晋中市", "运城市", "忻州市", "临汾市", "吕梁市"],
    "贵州省": ["贵阳市", "六盘水市", "遵义市", "安顺市", "毕节市", "铜仁市", "黔西南布依族苗族自治州", "黔东南苗族侗族自治州", "黔南布依族苗族自治州"],
    "云南省": ["昆明市", "曲靖市", "玉溪市", "保山市", "昭通市", "丽江市", "普洱市", "临沧市", "楚雄彝族自治州", "红河哈尼族彝族自治州", "文山壮族苗族自治州", "西双版纳傣族自治州", "大理白族自治州", "德宏傣族景颇族自治州", "怒江傈僳族自治州", "迪庆藏族自治州"],
    "甘肃省": ["兰州市", "嘉峪关市", "金昌市", "白银市", "天水市", "武威市", "张掖市", "平凉市", "酒泉市", "庆阳市", "定西市", "陇南市", "临夏回族自治州", "甘南藏族自治州"],
    "青海省": ["西宁市", "海东市", "海北藏族自治州", "黄南藏族自治州", "海南藏族自治州", "果洛藏族自治州", "玉树藏族自治州", "海西蒙古族藏族自治州"],
    "台湾省": ["台北市", "高雄市", "新北市", "台南市", "台中市", "桃园市", "新竹市", "嘉义市", "基隆市", "新竹县", "苗栗县", "彰化县", "南投县", "云林县", "嘉义县", "屏东县", "宜兰县", "花莲县", "台东县", "澎湖县"],
    "香港特别行政区": ["香港岛", "九龙", "新界"],
    "澳门特别行政区": ["澳门半岛", "氹仔岛", "路环岛"],
    "广西壮族自治区": ["南宁市", "柳州市", "桂林市", "梧州市", "北海市", "防城港市", "钦州市", "贵港市", "玉林市", "百色市", "贺州市", "河池市", "来宾市", "崇左市"],
    "内蒙古自治区": ["呼和浩特市", "包头市", "乌海市", "赤峰市", "通辽市", "鄂尔多斯市", "呼伦贝尔市", "巴彦淖尔市", "乌兰察布市", "兴安盟", "锡林郭勒盟", "阿拉善盟"],
    "西藏自治区": ["拉萨市", "昌都市", "山南市", "日喀则市", "那曲市", "阿里地区", "林芝市"],
    "宁夏回族自治区": ["银川市", "石嘴山市", "吴忠市", "固原市", "中卫市"],
    "新疆维吾尔自治区": ["乌鲁木齐市", "克拉玛依市", "吐鲁番市", "哈密市", "昌吉回族自治州", "博尔塔拉蒙古自治州", "巴音郭楞蒙古自治州", "阿克苏地区", "克孜勒苏柯尔克孜自治州", "喀什地区", "和田地区", "伊犁哈萨克自治州", "塔城地区", "阿勒泰地区", "石河子市", "阿拉尔市", "图木舒克市", "五家渠市", "北屯市", "铁门关市", "双河市", "可克达拉市", "昆玉市"],
    }
    //根据province返回城市数组
    if(province){
        return data[province]
    }
    //否则返回所有省份
    return Object.keys(data)
}


export {checkDate,checkLogin,localDate,localDate2,
    getProvince,
    isAdmin,toFold,DB,share,getNicknameOrUsername}