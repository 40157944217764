

// 话题详情页 该话题被精选的话题列表

import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Pagination } from "antd"

import style from "./selected.module.css"
export function SelectedList({page,order,list,getSelected}){


    useEffect(()=>{
        getSelected(page,order)
        // eslint-disable-next-line
    },[])

    return <div>
        {list.list.map(e=><div key={e.id} className={style.title}>
                <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
        </div>)}
        <Pagination 
            total={list.total}
            current={list.page}
            pageSize={18}
            showSizeChanger={false} 
            onChange={p=>getSelected(p)}
        />
    </div>
    
}