


import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import style from "./css/replys.module.css"
import { useEffect, useState } from "react"
import { Button, Input, Pagination } from "antd"
import { localDate, share, toFold } from "../../tools"
import { axios } from "../../axios"
import Alert from "../my-com/alert"


// 回复的回复
export default function PhoneReplys(){

    const navi = useNavigate()

    const replyId = useParams().id

    const [searchParmas,setSearchParmas] = useSearchParams()
    const page= searchParmas.get("page")?parseInt(searchParmas.get("page")):1
    const [replys,setReplys] = useState({
        list:[],
        total:0,
        page:page
    })

    const [postDetail,setPostDetail] = useState({})
    const [replyDetail,setReplyDetail] = useState({})
    const [add,setAdd] = useState({
        reply:""
    })
    //是否置灰提交按钮
    const [submitBtnDisable,setSubmitBtnDisable] = useState(false)
    
    const [showFullContent,setShowFullContent]=useState(false)
    const [foldRepyId,setFoldReplyId] = useState(null)


    useEffect(()=>{
        getReplys()
        getReplyAndPost()
        //eslint-disable-next-line
    },[replyId])



    return<div className={style.container}>
        <h3 onClick={()=>{
                navi("/topic/post/detail/"+postDetail.id)
            }}><span>话题 </span>{postDetail.title}
        </h3>
        
        <hr/>

        <div className={style.father}>
            <div className={style.replyId}>#{replyDetail.id}</div>
            <div className={style.replyHeader}>
                <span className={style.user} 
                    onClick={()=>navi("/user/profile/"+replyDetail.user.id)}>{getNicknameOrUsername(replyDetail.user)}</span>
                <span className={style.date}>{localDate(replyDetail.createDatetime)}</span>
            </div>

            {/* 回复内容 */}
            <p className={toFold(replyDetail.content)&&!showFullContent?style.replyContentHide:style.replyContent}>
                <span>{replyDetail.content}</span>
            </p>
            {toFold(replyDetail.content)&&<div>
                        {showFullContent?<span onClick={()=>setShowFullContent(false)} style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>:
                        <span onClick={()=>setShowFullContent(true)} style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>}
                    </div>}


            <div className={style.btns}>
                <span>回复数{replyDetail.replyCount}</span>
                <span onClick={()=>starReplyFather(replyDetail)}>顶{replyDetail.star}</span>
                <span onClick={()=>unstarReplyFather(replyDetail)}>踩{replyDetail.unstar}</span> 
                {replyDetail.pid!==0&&<span onClick={()=>navi("/topic/post/reply/"+replyDetail.pid)}>上层回复</span>}
                {!replyDetail.archived&&<span onClick={()=>archiveReplyFather(replyDetail)}>收藏</span>}
                {replyDetail.archived&&<span onClick={()=>unarchiveReplyFather(replyDetail)}>取消收藏</span>}
                <span onClick={()=>share("/#/topic/post/reply/"+replyDetail.id)}>分享</span>
            </div>
        </div>

        <hr/>

        <div>
            <div>
                <Input.TextArea placeholder={"回复 回复#"+replyDetail.id} 
                    onChange={(v)=>{
                        setAdd({...add,reply:v.target.value})
                    }} 
                    maxLength={2000}
                    value={add.reply}
                    autoSize={true} />
                <p>
                    <Button 
                        disabled={submitBtnDisable?true:false} 
                        onClick={()=>{addReply()}}>提交</Button>
                </p>
            </div>
        </div>

        <div className={style.replys}>
            {replys.list.map(e=><div key={e.id} className={style.reply}>
                <div className={style.id}>#{e.id}</div>
                <div className={style.replyHeader}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+e.user.id)}>{getNicknameOrUsername(e.user)}</span>
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </div>

                {/* <p className={style.content}>{e.content}</p> */}
                <p className={toFold(e.content)&&e.id!==foldRepyId?style.contentHide:style.content}>{e.content}</p>
                {toFold(e.content)&&<>{foldRepyId===e.id?<p>
                    <span onClick={()=>setFoldReplyId(null)} style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>
                </p>:<p><span onClick={()=>setFoldReplyId(e.id)} style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span></p>}</>}  

                <div className={style.replyFoot}>
                    <span onClick={()=>navi("/topic/post/reply/"+e.id)}>回复{e.replyCount}</span>
                    <span onClick={()=>starReply(e)} className={e.stared?style.active:""}>顶{e.star}</span>
                    <span onClick={()=>unstarReply(e)} className={e.unstared?style.active:""}>踩{e.unstar}</span> 
                    {!e.archived&&<span onClick={()=>archiveReply(e)}>收藏</span>}
                    {e.archived&&<span onClick={()=>unarchiveReply(e)} className={style.active}>取消收藏</span>}
                    <span onClick={()=>share("/topic/post/reply/"+e.id)}>分享</span>
                </div>
            </div>)}

            <Pagination 
                total={replys.total}
                current={replys.page}
                pageSize={18}
                onChange={(p)=>getReplys(p)}
                showSizeChanger={false}
                />
        </div>  
    </div>



    function getReplys(tmpPage){
        axios.get("/api/topic/reply",{
            params:{
                replyId,
                page:(tmpPage?tmpPage:replys.page)-1
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplys({...replys,
                    list:data.data.list,
                    page:tmpPage?tmpPage:replys.page,
                    total:data.data.total})
                setSearchParmas(pre=>{
                    let tmp = new URLSearchParams(pre)
                    tmp.set("page",tmpPage?tmpPage:replys.page)
                    return tmp.toString()
                })
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function addReply(){    
        if(!add.reply){
            return
        }
        //长度不能超过2000
        if(add.reply.length>2000){
            alert("回复内容不能超过2000个字符")
            return
        }
        setSubmitBtnDisable(true);
        axios.post("/api/topic/reply",{
            pId:replyId,
            reply:add.reply
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
                setAdd({...add,reply:""})
                setSubmitBtnDisable(false)
            }else{
                setSubmitBtnDisable(false)
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{
            setSubmitBtnDisable(false)
        })
    }

    function getReplyAndPost(){
        axios.get("/api/topic/reply/detail-with-topic",{
            params:{
                replyId:replyId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostDetail(data.data.post)
                setReplyDetail(data.data.reply)
            }else{
                navi("/topic")
            }
        })
    }



    // 
    function getNicknameOrUsername(user){
        if(!user){
            return ""
        }
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }



    // 顶踩收藏评论
    function starReplyFather(e){
        if(e.stared){
            return
        }
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{})
    }
    function starReply(e){
        if(e.stared){
            return
        }
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{})
    }
    function unstarReplyFather(e){
        if(e.unstared){
            return
        }
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{})
    }
    function unstarReply(e){
        if(e.unstared){
            return
        }
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{})
    }

    function archiveReplyFather(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            } 
        }).catch(err=>{})
    }
    function archiveReply(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = true
                    }
                })
                setReplys({...replys,list:tmps})
            } 
        }).catch(err=>{})
    }

    function unarchiveReplyFather(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            getReplyAndPost()
        }).catch(err=>{})
    }
    function unarchiveReply(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = false
                    }
                })
                setReplys({...replys,list:tmps})
            }    
        }).catch(err=>{})
    }

}







