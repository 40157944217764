import React from "react";
import ReactDOM from 'react-dom/client';

import style from "./index.module.css"

export default class Alert extends React.Component{
    render(){
        return <div className={style.alert}>
            <p className={style[this.getMsgType(this.props.type)]}>{this.props.msg}</p>
        </div>
    }

    getMsgType = (type)=> {
        if(type==="success"){return "success"}
        if(type==="fail"){return "fail"}
        return "msg"
    }

    static alert(msg,type){
        let node = document.createElement("div");
        document.body.appendChild(node);
        const tmpRoot = ReactDOM.createRoot(node);
        tmpRoot.render(<Alert msg={msg} type={type}  />)
        setTimeout(()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
        },3000)
    }
    static alertAndDo(msg,type,func){
        let node = document.createElement("div");
        document.body.appendChild(node);
        const tmpRoot = ReactDOM.createRoot(node);
        tmpRoot.render(<Alert msg={msg} type={type}  />)
        setTimeout(()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
            func()
        },3000)

    }
    
}