import { Select } from "antd"
import { useState } from "react"
import { getProvince } from "../../../../tools"


export default function CityEditor(props){

    // const [value,setValue] = useState(props.value)
    const [citys,setCitys] = useState([])

    const [location,setLocation] = useState({
        province:null,
        city:null
    })
    return<div>
        <div>
            <Select 
                value={location.province}
                style={{width:"12em"}}
                placeholder="省份"
                options={getProvince()
                    .map(item=>({label:item,value:item})) } 
                onChange={(v)=>{
                    setLocation({province:v,city:null})
                    setCitys(getProvince(v))
                    props.back({province:v,city:null})
                }}
                />
            <Select 
                value={location.city}
                style={{width:"14em"}}
                allowClear
                placeholder="城市"
                options={citys.map(item=>({label:item,value:item}))}
                onChange={(v)=>{
                    setLocation({...location,city:v})
                    props.back({province:location.province,city:v})
                }}
            />
        </div>
    </div>
}