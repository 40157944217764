

import { useContext, useEffect, useState } from "react"
import style from "./index.module.css"
import { axios } from "../../axios"
import { useNavigate } from "react-router-dom"
import { Button, Input } from "antd"

import Alert from "../my-com/alert"
import { DB } from "../../tools"
import { USER } from "../../context"

export default function Login(){

    const navigater = useNavigate()
    const [loginOrRegister,setLoginOrRegister]=useState('login')
    const [allowRegister,setAllowRegister]=useState(false)

    const userContext = useContext(USER)

    const [registerInfo,setRegisterInfo]=useState({
        username:'',
        nickname:'',
        pw1:'',
        pw2:'',
        email:''
    })

    const [loginInfo,setLoginInfo]=useState({
        username:'',
        password:''
    })

    useEffect(()=>{
        checkIsAllowUserRegister()
    },[])


    return<div>
        <div className={style.container}>
            <p>
                <span 
                    className={[style.btn,loginOrRegister==='login'?style.activeBtn:''].join(" ")}
                    onClick={()=>setLoginOrRegister('login')}>登录</span> 
                / 
                <span 
                    className={[style.btn,loginOrRegister==='register'?style.activeBtn:''].join(" ")}
                    onClick={()=>setLoginOrRegister('register')}>注册</span>
            </p>

            {loginOrRegister==='login'?<div className={style.loginContainer}>
                <input placeholder="帐号" onChange={e=>setLoginInfo({...loginInfo,username:e.target.value})} />
                <input placeholder="密码" type='password' onChange={e=>setLoginInfo({...loginInfo,password:e.target.value})} />
                <p>
                    <Button type="dashed" onClick={submitLogin}>登录</Button>
                </p>
            </div>:<div className={style.registerContainer}>
                {allowRegister?<div className={style.register}>
                    <Input placeholder="昵称" 
                        onChange={e=>setRegisterInfo({...registerInfo,nickname:e.target.value})} />
                    <Input placeholder="帐号" onChange={e=>checkInput(e.target.value)}/>
                    <Input placeholder="密码" type='password' 
                        onChange={e=>setRegisterInfo({...registerInfo,pw1:e.target.value})}/>
                    {/* 鼠标离开，就检测两次密码是否一致 */}
                    <Input placeholder="再次输入密码" type='password' 
                        onChange={e=>setRegisterInfo({...registerInfo,pw2:e.target.value})}/>
                    <Input placeholder="选填，邮箱(用来找回密码)" 
                        onChange={(e)=>setRegisterInfo({...registerInfo,email:e.target.value})}/>

                    <Button onClick={submitregister}>提交</Button>
                    <Button onClick={()=>setLoginOrRegister('login')}>已有帐号去登录</Button>
                </div>:<div>
                    <p>暂不允许注册</p>    
                </div>}
            </div>}
        </div>
    </div>


    function submitLogin() {
        if(!loginInfo.username || !loginInfo.password){
            Alert.alert("用户名、密码不能为空","fail")
            return
        }
        //只能包含字母和数字
        {
            let username = loginInfo.username;
            for(let i=0;i<username.length;i++){
                let code = username.charCodeAt(i);
                if(i===0 && !((code>=65 && code<=90) || (code>=97 && code <= 122))){
                    Alert.alert("用户名以字母开头且只能包含字母、数字","fail")
                    return;
                }
                
                if(!((code>=65 && code<=90) || (code>=97 && code <= 122) || (code>=48 && code<=57))){
                    Alert.alert("用户名以字母开头且只能包含字母、数字","fail")
                    return;    
                }
            }
        }
        axios.post("/api/user/login",{
            username:loginInfo.username,
            password:loginInfo.password
        }).then(({data})=>{
            if(data.code===200){
                localStorage.setItem("info",JSON.stringify(data.data))
                userContext.setLogin()
                new DB().clear("profiles")
                let preURL = localStorage.getItem("preURL")
                if(preURL){
                    window.location.href=preURL
                }else{
                    navigater('/flash')
                }
            }else{
                Alert.alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function checkInput(e){
        if(!e){
            setRegisterInfo({...registerInfo,username:e})
            return
        }
        let firstC = e.charCodeAt(0)
        if((firstC>=65 && firstC<=90)||(firstC>=97 && firstC<=122)){

        }else{
            Alert.alert("必须以字母开头")
            return
        }
        for(let i=0;i<e.length;i++){
            let code = e.charCodeAt(i)
            if((code>=65 && code<=90)||(code>=97 && code<=122) || (code>=48 && code<=57)){
            }else{
                Alert.alert("只能输入字母、数字")
                return
            }
        }
        setRegisterInfo({...registerInfo,username:e})
    }


    function submitregister(){
        if(!registerInfo.username || !registerInfo.pw1 || !registerInfo.pw2){
            Alert.alert("用户名和密码不能为空","fail")
            return
        }
        if(registerInfo.pw1!==registerInfo.pw2){
            Alert.alert("两次密码不一致","fail")
            return
        }
        //只能包含字母和数字
        {
            let username = registerInfo.username;
            for(let i=0;i<username.length;i++){
                let code = username.charCodeAt(i);
                if(i===0 && !((code>=65 && code<=90) || (code>=97 && code <= 122))){
                    Alert.alert("用户名以字母开头且只能包含字母、数字");
                    return;
                }
                if(!((code>=65 && code<=90) || (code>=97 && code <= 122) || (code>=48 && code<=57))){
                    Alert.alert("用户名以字母开头且只能包含字母、数字");
                    
                    return;    
                }
            }
        }
        axios.post("/api/user",{
            username:registerInfo.username,
            nickname:registerInfo.nickname,
            password:registerInfo.pw1,
            email:registerInfo.email,
        }).then(({data})=>{
            if(data.code===200){
                Alert.alert("注册成功","success")
                setLoginOrRegister('login')
            }else{
                Alert.alert(data.msg,"fail")
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    function checkIsAllowUserRegister(){
        axios.get("/api/system/setting/user-register").then(({data})=>{
            if(data.code===200){
                setAllowRegister(data.data)
            }
        }).catch(err=>{})  
    }
}




