
//成员资格

export default function VIP(){
    return <div>
        调试中...
    </div>
}
function VIPT(){

    return <div>
        成员资格

        为了社区成员、内容质量，社区采用会员制。

        非社区成员不开放寻ta功能，动态和话题功能有限开放。

        <div>
            <span>12 r/month</span>
            <span>120 r/year</span>
        </div>
        <div>
            <p>学生、失业人员、老、病、残等弱势人士：</p>
            <span>9 r/month</span>
            <span>90 r/year</span>
        </div>

        <div>
            因为支付接口还未调通，我们在寻求合规化解决方案，目前每次采用每月赠送月卡的方式免费使用。每月一次。
        </div>
        <div>
            <p>
                服务器、带宽成本高昂，目前我们的创业资金已经所剩无几，开发运营人员苦苦坚持中，
                如果您认可浮尘社区的理念且您力有所及，欢迎您通过以下方式捐赠给我们一点支持。
                感谢。
            </p>
        </div>
    </div>
}


