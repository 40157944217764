import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import Alert from "../../../my-com/alert"
import Btn from "../../../my-com/btn"
import { Input } from "antd"
import { DB } from "../../../../tools"

import style from "./index.module.css"
import Flashs from "./flashs"
import Posts from "./posts"
import Replies from "./replies"
import ArchivedPosts from "./archived.posts"
import ArchivedReplies from "./archived.replies"
import { useSearchParams } from "react-router-dom"


export default function MyProfile(props) {
    const [searchParams,setSearchParams] = useSearchParams()
    const [profile,setProfile] = useState({})
    const [card,setCard] = useState(1)
    useEffect(()=>{
        //从url中获取当前tab和page
        let tab = searchParams.get("tab")
        if(tab){
            setCard(parseInt(tab))
        }
        getUserProfile()
        // eslint-disable-next-line
    },[])

    const [edit,setEdit] = useState({
        show:false,
        title:"",
        field:"",
        value:""
    })


    return <div className={style.container}>
        {!edit.show&&<div>
            <div className={style.items}>
                <div className={style.item}>
                    <span className={style.placeholder}>帐号</span>
                    <span style={{color:"gray"}}>{profile.username}</span>
                </div>
                <div className={style.item}>
                    <span className={style.placeholder}>昵称:</span>
                    <span onClick={()=>toEdit("nickname")}>{profile.nickname}</span>
                </div>
                <div className={style.itemNextLine}>
                    <div className={style.placeholder}>个性签名:</div>
                    <div className={style.content} onClick={()=>toEdit("signature")}>
                        <span className={style.text}>
                            {profile.signature}
                        </span>
                    </div>
                </div>
                <div className={style.item} onClick={()=>toEdit("gender")}>
                    <span className={style.placeholder}>性别</span>
                    <span>{profile.gender===1?"男":"女"}</span>
                </div>
            </div>

            <div className={style.myActivity}>
                <div className={style.cardBtns}>
                    <span onClick={()=>setTab(1)} className={card===1?style.active:""}>动态</span>
                    <span onClick={()=>setTab(2)} className={card===2?style.active:""}>话题</span>
                    <span onClick={()=>setTab(3)} className={card===3?style.active:""}>回复</span>
                    <span onClick={()=>setTab(4)} className={card===4?style.active:""}>
                        <sup style={{fontSize:"0.5em"}}>收藏的</sup>话题
                    </span>
                    <span onClick={()=>setTab(5)} className={card===5?style.active:""}>
                        <sup style={{fontSize:"0.5em"}}>收藏的</sup>回复
                    </span>
                </div>
                
                {profile.userId&&<>
                    {card===1&&<Flashs userId={profile.userId} />}
                    {card===2&&<Posts />}
                    {card===3&&<Replies />}
                    {card===4&&<ArchivedPosts />}
                    {card===5&&<ArchivedReplies />}
                </>}
            </div>
        </div>}

        {edit.show&&<div className={style.edit}>
            <div className={style.item}>
                <div className={style.headBtns}>
                    <Btn onClick={()=>setEdit({...edit,show:false})} text="取消"/>
                    <Btn confirm onClick={submitEdit} text="确定"/>
                </div>
                <div style={{textAlign:"center",fontWeight:"bolder"}}>
                    <span>{edit.title}</span>
                </div>
            </div>
            <div className={style.item}>
                <div>
                    {edit.field!=="description"&&<input value={edit.value} onChange={v=>setEdit({...edit,value:v.target.value})} />}
                    {edit.field==="description"&&<Input.TextArea autoSize
                        value={edit.value} onChange={v=>setEdit({...edit,value:v.target.value})} />}
                </div>
            </div>
        </div>}
    </div>


 
    //修改url中tab参数
    function setTab(tab){
        setCard(tab)
        setSearchParams((pre)=>{
            let newParams = new URLSearchParams(pre)
            newParams.set("tab",tab)
            newParams.set("page",1)
            return newParams
        })
    }
    //
    function toEdit(field) {
        let title = "";
        let value = "";
        if(field==="nickname"){
            title = "修改昵称"
            value = profile.nickname
        }else if(field==="signature"){
            title = "修改个性签名"
            value = profile.signature
        }else if(field==="gender"){
            title = "修改性别"
            value = profile.gender===1?"男":"女"
        }
        setEdit({...edit,show:true,title,field,value})
    }

    function checkDate(data){
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if(regex.test(data)===false){
            Alert.alert("格式不正确","fail")
            return false
        }
        let tmps =data.split("-")
        let tmpsYear = parseInt(tmps[0])
        let tmpsMonth = parseInt(tmps[1])
        let tmpsDay = parseInt(tmps[2])

        let tmp = new Date(data)
        let year = tmp.getFullYear()
        let month = tmp.getMonth()+1
        let day = tmp.getDate()

        if(year!==tmpsYear || month!==tmpsMonth || day!==tmpsDay){
            Alert.alert("格式不正确","fail")
            return false
        }
        return true
    }

    function submitEdit(){
        if(!edit.value){
            Alert.alert("别填空的啊","fail")
            return
        }
        if(edit.field==="gender"){
            if(edit.value!=="男" && edit.value!=="女"){
                Alert.alert("性别只能填男或女","fail")
                return
            }
        }
        if(edit.field==="birthday"){
            if(checkDate(edit.value)===false){
                Alert.alert("生日格式不正确","fail")
                return
            }
        }

        axios.put("/api/user/profile",{
            field:edit.field,
            value:edit.value
        }).then(({data})=>{
            if(data.code===200){
                setEdit({...edit,show:false})
                //先清除数据库
                new DB().delete("profiles",props.userId)
                .then(data=>{
                    getUserProfile()
                }).catch(err=>{})
            }
        }).catch(err=>{})
    }


    function getUserProfile(){
        new DB().getByKey("profiles",props.userId)
        .then(data=>{
            setProfile(data)
        }).catch(err=>{
            axios.get('/api/user/profile')
            .then(({data})=>{
                if(data.code===200){
                    setProfile(data.data)
                    new DB().add("profiles",props.userId,data.data)
                }
            })
        })

    }
}


